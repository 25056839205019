<template>
  <div class="journalism">
    <div>
      <div
        class="hero is-cover is-relative is-fullheight-with-navbar is-primary"
      >
        <div>
          <h1
            class="home-text-home home-text-head side-txt"
          >
            In the streets!
          </h1>
          <div class="main-image main-image-home ">
            <img src="../assets/images/DSCF9921.874e4983.jpg" width="1000px" />
          </div>
          <div class="main-image"><img src="../assets/images/DSCF0354.8af87050.jpg" /></div>
          <div class="main-image"><img src="../assets/images/DSCF0334.2cfd73cc.jpg" /></div>
          <div class="main-image"><img src="../assets/images/DSCF0396.c3a18c8a.jpg" /></div>
          <div class="main-image"><img src="../assets/images/DSCF0365.bdeeb5a5.jpg" /></div>
          <div class="main-image"><img src="../assets/images/DSCF0350.f1d4fb4a.jpg" /></div>
          <div class="main-image"><img src="../assets/images/DSCF0382.cb132c03.jpg" /></div>
          <div class="main-image"><img src="../assets/images/DSCF9985.2ed85134.jpg" /></div>
          <div class="main-image"><img src="../assets/images/IMG_0005.5e0e2a95.jpg" /></div>
          <div class="word-nav">
               <router-link to="/travel"><h1 class="home-text-nav">Travel</h1></router-link>
               <router-link to="/portrait"><h1 class="home-text-nav">Portraits</h1></router-link>
               <router-link to="/contact"><h1 class="home-text-nav">Contact</h1></router-link>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

    mounted () {
  window.scrollTo(0, 0)
}


}

</script>

