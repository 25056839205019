<template>
  <div class="travel">
    <div>
      <div class="">
        <div>
          <h1
          class="home-text-home home-text-head side-txt"
           
          >
            Around the world!
          </h1>
          <div class="main-image main-image-home">
            <img src="../assets/images/IMG_0536-1.66861473.jpeg" />
          </div>
          <h1 changetext="" class="smith-txt side-txt">
            Havana Cuba&gt;&gt;&gt;
          </h1>
          <div class="main-image">
            <img src="../assets/images/IMG_0497.76e9ef39.jpeg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/IMG_0175.7d26e845.jpg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/fullsizeoutput_99c8.b4d3c1ac.jpeg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/fullsizeoutput_99c9.d5e1e9b1.jpeg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/fullsizeoutput_9a95.37cff45f.jpeg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/fullsizeoutput_9a4b.6711b649.jpeg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/fullsizeoutput_9a4a.9cc9f056.jpeg" />
          </div>
          <div class="word-nav">

                
        <router-link to="/portrait#top"><h1 class="home-text-nav">Portrait</h1></router-link>
        <router-link to="/journalism"><h1 class="home-text-nav">Journalism</h1></router-link>
        <router-link to="/contact"><h1 class="home-text-nav">Contact</h1></router-link>


          </div>
        </div>
      </div>
    </div>
  </div>
  </template>

<script>

export default {

  metaInfo: {
    title: 'Travel Photography ',
    
    meta: [
    
      { name: 'description', content: 'Images from my travels around the world.' },
      { name: 'keywords', content: 'travel photography, miami, cuba, world photography, travel blogger'},
      ]
      
},
    mounted () {
  window.scrollTo(0, 0)
}


}

</script>
