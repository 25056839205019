<template>

	<div class="home">

		<div class="hero is-cover is-relative is-fullheight-with-navbar is-primary">

			<h1 class="home-text-home home-text-head">
				<div id="header-text-home">Glenford Nunez <br> Photography</div>
			</h1>

			<div>
				<div class="main-image-home">
					<img alt="Lilly Lightbourn Portrait" src="../assets/images/DSC_2251-Edit.jpg" width="1000px" />
				</div>



				<div class="word-nav">
					<router-link to="/travel">
						<h1 class="home-text-nav">Travel</h1>
					</router-link>
					<router-link to="/portrait">
						<h1 class="home-text-nav">Portrait</h1>
					</router-link>
					<router-link to="/journalism">
						<h1 class="home-text-nav">Journalism</h1>
					</router-link>

				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	data() {
		return {

		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
}
</script>
