<template>
  <div class="portrait">

    <div>
      <div class="hero is-cover is-relative is-fullheight-with-navbar is-primary">
        <div>
          <h1 class="home-text-home home-text-head side-txt" style="margin: -15px -25px 0px 0px;">
            <div id="portrait-header">Real People Pics</div>
          </h1>

          <div class="main-image main-image-home">
            <img src="../assets/images/Pearl-Fils-Aime-Glenford-Nunez.jpg" />
          </div>

          <div class="main-image main-image-home">
            <img src="../assets/images/DSCF8762.6c7a9fdf.jpg" />
          </div>
          <h1 class="smith-txt side-txt">Street Portraits&gt;&gt;&gt;</h1>
          <div class="main-image"><img src="../assets/images/DSCF8826.f2a7ee85.jpg" /></div>
          <div class="main-image"><img src="../assets/images/DSCF8869.c54ea6e8.jpg" /></div>
          <div class="main-image">
            <img src="../assets/images/last-burstio-export.7cd9c47e.gif" />
          </div>
          <div style="width: 100%; clear: both;"></div>
          <h1 class="smith-txt side-txt">Fashion Portraits></h1>
          <div class="main-image">
            <img src="../assets/images/DSC_4836-Edit.jpg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/DSC_8919-Edit-1.png" />
          </div>
          <div class="main-image">
            <img src="../assets/images/DSC_7030-Edit-4-1.5dbde68a.jpg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/Nejilka_Portrait_Glenford_Nunez.2cbf06ac.jpg" />
          </div>
          <div class="main-image">
            <h1 class="smith-txt side-txt">Top Models&gt;&gt;&gt;</h1>
            <img src="../assets/images/DSC_7655-Edit.e5a1fed9.jpg" />
          </div>
          <div class="main-image">
            <img alt="doyla delgado by Glenford Nunez Fashion Portrait Lisbon Portugal "
              src="../assets/images/DSC_2251-Edit.jpg" />
          </div>

          <div class="main-image">
            <img src="../assets/images/DSC_1044-Edit-2.9a7991b7.jpg" />
          </div>
          <div class="main-image"><img src="../assets/images/IMG_6748.gif" /></div>
          <div class="main-image">
            <img src="../assets/images/Ashely-Irving-Portrait-3.67980082.jpg" />
          </div>
          <div style="width: 100%; clear: both;"></div>
          <h1 side-txt="" class="home-text-nav">Celeb Portraits&gt;</h1>
          <div class="main-image">
            <img src="../assets/images/DSCF7246-Edit.1a2b55d0.jpg" />
          </div>
          <div class="main-image">
            <img src="../assets/images/DSCF7587-Edit-2.398b1826.jpg" width="800px" />
          </div>
          <div class="main-image">
            <img src="../assets/images/12_COVER_PDN_byGlenfordNunez.0eab5892.jpg" width="600px" />
          </div>
          <div class="word-nav">
            <router-link to="/travel">
              <h1 class="home-text-nav">Travel</h1>
            </router-link>
            <router-link to="/journalism">
              <h1 class="home-text-nav">Journalism</h1>
            </router-link>
            <router-link to="/contact">
              <h1 class="home-text-nav">Contact</h1>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

    metaInfo: {
    title: 'Top Portrait Photographer',
    
    meta: [
    
      { name: 'description', content: 'World class portrait photography by Glenford Nunez' },
      { name: 'keywords', content: 'portrait photography, black women natural hair, black models, top models, beauty'},

      ]
      
	},	  
    mounted () {
  window.scrollTo(0, 0)

}


}

</script>

