// Importing necessary libraries and plugins
import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import { BootstrapVue } from 'bootstrap-vue';
import App from "./App.vue";
import router from "./router";
import VueMeta from 'vue-meta';

// Using the imported plugins
Vue.use(BootstrapVue);
Vue.use(VueMeta, {
  // Optional plugin options
  refreshOnceOnNavigation: true
});

// Configurations
Vue.config.productionTip = false;

// Creating and mounting the Vue instance
new Vue({
  router,
  render: h => h(App),
  data: function() {
    return {};
  },
}).$mount("#app");
