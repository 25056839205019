<template>
  <div id="app">
    <a name="top"></a>
    <div v-scroll="handleScroll"></div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a href="/" class="top-logo router-link-active">
          <img src="./assets/images/Nunez-Logo-G.png" height="28"
        /></a>

        <a
          role="button"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          class="navbar-burger burger"
        >
          <span aria-hidden="true"></span><span aria-hidden="true"></span
          ><span aria-hidden="true"></span
        ></a>
      </div>
      <div class="navbar-menu">
        <!-- navbar start, navbar end -->
        <router-link to="/">Home</router-link>
        <router-link to="/travel">Travel</router-link>
        <router-link to="/portrait">Portrait</router-link>
        <router-link to="/journalism">Journalism</router-link>
        <router-link to="/contact">Contact</router-link>
      </div>
    </nav>
    <router-view />

    <footer>
      <a href="/#top">
        <div id="footer-img">
          <img
            v-show="active"
            @mouseover="active = !active"
            @mouseout="active = active"
            src="./assets/images/Nunez-Logo-G.png"
            height="50"
          />

          <img
            v-show="!active"
            @mouseover="active = active"
            @mouseout="active = !active"
            src="./assets/images/Nunez-Logo-O.png"
            height="50"
          />
        </div>
      </a>
    </footer>
  </div>
</template>

<script>
export default {

	metaInfo: {
	title: 'Glenford Nunez Photography - Best Photographer',
	},

  components: {},

  data() {
    return {
      active: true,
    };
  },

  methods: {
  /* 
  
  handleScroll: function(evt, el) {
      if (window.scrollY > 0) {
        document.getElementById("header-text-home").innerHTML =
          "Glenford Nunez </br> Photography";
      }

      if (window.scrollY > 1000) {
        document.getElementById("header-text-home").innerHTML =
          "I can shoot...";

        el.setAttribute(
          "style",
          "opacity: 1; transform: translate3d(0, -10px, 0)"
        );
      }
  
      if (window.scrollY > 2320) {
        document.getElementById("header-text-home").innerHTML = "Anything!";
      }

     if (window.scrollY > 100) {
        document
          .getElementById("portrait-header").setAttribute("style", "display: none;");
      }

      return window.scrollY > 100;
    },*/
  },
};
</script>

<style lang="sass">
@import "./assets/css/my.sass"
@import url("https://use.typekit.net/xmn5dsl.css")
</style>
