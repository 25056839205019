import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Travel from "../views/Travel.vue";
import Portrait from "../views/Portrait.vue";
import Journalism from "../views/Journalism.vue";
import Contact from  "../views/Contact.vue";

Vue.use(VueRouter);




const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },

  {
    path: "/travel",
    mame: "Travel",
    component: Travel
  },
  {
    path: "/portrait",
    name: "Portrait",
    component: Portrait
  },

  {
    path: "/journalism",
    name: "Journalism",
    component: Journalism
  },

  {
    path: "/contact",
    name: "Contact",
    component: Contact
  }
];

const router = new VueRouter({
  routes,

  
});

export default router;
